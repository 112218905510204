import React from 'react'
import './inspectCat.css'

const InspectCat = ( props ) => {
    return (
        <div className = "categoryTitle">
            {props.desc}
        </div>
    );
}

export default InspectCat;