import React, { useContext, useState } from 'react'
import { Link, useHistory } from "react-router-dom";
import AppContext from '../appContext'
import { fbapp } from "../base";
// Pages
import LoaderPage from "../pages/LoaderPage";
// Components
import InspectHead from '../components/inspectHead/inspectHead'
import InspectCat from '../components/inspectCat/inspectCat'
import InspectItem from '../components/inspectItem/inspectItem'
// Styles
import './inspect.css';

function Inspect() {

  // local state
  const [isLoading, setIsLoading] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(true);
  // context
  const history = useHistory();
  const { iPhotos, setiData, updatePhoto, auth, setAuth, ...gVar } = useContext(AppContext);

  const saveInspection = async (backToReport, oldRef) => {
    setSaveSuccess(true);
    setIsLoading(true);
    // authenticate 
    if (!auth) {
      var fbAuth = await fbapp.auth().signInWithEmailAndPassword(process.env.REACT_APP_U, process.env.REACT_APP_P);
      setAuth(fbAuth);
    }
    try {
      // connect to firebase storage
      const storageRef = fbapp.storage().ref();
      // save iData as json
      var iDataFileRef = storageRef.child(gVar.custId + "/" + gVar.iData.reportRef.value + "-i.json");
      var iDataUTask = await iDataFileRef.putString(JSON.stringify(gVar.iData));
      if (!iDataUTask) {
        console.log("iDataUTask not set");
      }
      // save Photo data as json
      var iPhotoFileRef = storageRef.child(gVar.custId + "/" + gVar.iData.reportRef.value + "-p.json");
      var iPhotoUTask = await iPhotoFileRef.putString(JSON.stringify(iPhotos));
      if (!iPhotoUTask) {
        console.log("iPhotoUTask not set");
      }
    } catch (err) {
      console.log(err);
      console.log("Json files for customer " + gVar.iData.custId + " not saved.")
      setIsLoading(false);
      // set the save to failed so that we don't proceed
      setSaveSuccess(false);
      // alert the user that we had an issue saving the inspection
      alert("Oops, something went wrong. Inspection " + gVar.iData.reportRef.value + " not saved")
    };
    if (saveSuccess) {
      // only remove files if there is an old file name AND it's different than the new one just saved
      if (oldRef && oldRef !== gVar.iData.reportRef.value) {
        try {
          // connect to firebase storage
          const storageRef = fbapp.storage().ref();
          // if we got this far and we have an oldRef, then we will delete the old files
          if (oldRef) {
            // save iData as json
            iDataFileRef = storageRef.child(gVar.custId + "/" + oldRef + "-i.json");
            iDataUTask = await iDataFileRef.delete();
            // save Photo data as json
            iPhotoFileRef = storageRef.child(gVar.custId + "/" + oldRef + "-p.json");
            iPhotoUTask = await iPhotoFileRef.delete();
          }
        } catch (err) {
          console.log(err);
          console.log(gVar.iData.custId + ", Old Inspection " + oldRef + " files don't exist.")
        };
      }
      // set global saved flag
      gVar.setIsSaved(true);
      if (backToReport) {
        history.push("/report");
      } else {
        setIsLoading(false);
      }
    }
  }

  //called when setting rating light to green  
  const clearItem = (catId, itemId) => {
    const iDataNew = { ...gVar.iData };

    iDataNew.categories[catId].items[itemId].note1.value = "";
    iDataNew.categories[catId].items[itemId].note2.value = "";
    setiData(iDataNew);
    updatePhoto("", catId, itemId);
  }

  //called when updating report ref  
  const updateReportRef = (reportRef, oldRef) => {
    const iDataNew = { ...gVar.iData };

    iDataNew.reportRef.value = reportRef;
    setiData(iDataNew);
    saveInspection(false, oldRef);
  }

  //called when updating customer ref 
  const updateCustomerRef = (customerRef) => {
    const iDataNew = { ...gVar.iData };

    iDataNew.customerRef.value = customerRef;
    setiData(iDataNew);
    saveInspection(false, "");
  }

  // protect app render until initialized
  if (isLoading || gVar.iAuthData === 0 || gVar.iData.length === 0 || iPhotos.length === 0) {
    return <LoaderPage pageStyle="loadingWrapperDark" pageMsg="Please Wait"/>;
  }

  return (
    <div className="inspectHeadWrapper">
      <InspectHead
        updateReportRef={updateReportRef}
        updateCustomerRef={updateCustomerRef}
      />
      {gVar.iData.categories.map((c, catId) => {
        return (
          <div key={catId}>
            <InspectCat desc={c.catDesc} />
            {c.items.map((i, itemId) => {
              const imageData =
                iPhotos.categories[catId].items[itemId].photoData;
              return (
                <InspectItem
                  key={catId + "-" + itemId}
                  catId={catId}
                  itemId={itemId}
                  item={i}
                  imageData={imageData}
                  takePhotoOn={i.takePhotoOn}
                  clearItem={clearItem}
                />
              );
            })}
          </div>
        );
      })}
      <div className="actionRow">
        {gVar.isSaved ?
          <Link to="/report">
            <div className="actionButtonDark">
              Review Report
          </div>
          </Link>
          :
          <div className="actionButtonDark"
            onClick={() => saveInspection(true, "")}>
            Save and Review Report
          </div>}
      </div>
    </div>
  );
}

export default Inspect;