import React, { useState, useContext } from 'react';
import AppContext from '../../appContext';
import { ReactComponent as Verified } from "../../assets/Verified.svg";
import { ReactComponent as Expand } from "../../assets/Expand.svg";
import { ReactComponent as Collapse } from "../../assets/Collapse.svg";

import "./reportInspection.css";

function ReportInspection() {

  const gVar = useContext(AppContext);
  const [hideGood, setHideGood] = useState(false);

  var greenCatItems = '{ "categories": [';
  var lastCatId = gVar.iData.categories.length - 1;

  // build the json
  gVar.iData.categories.map((c, catId) => {
    var greenItems = c.items.filter((i) => {
      return i.rate.rating === "Green";
    });
    // add category and items
    greenCatItems = greenCatItems +
      '{ "catDesc": "' + c.catDesc + '", "items": ' + JSON.stringify(greenItems) + " }"
    // handle the last category (no ,)  
    if (catId !== lastCatId) { greenCatItems = greenCatItems + ',' };
    return 0;
  });
  // close off the json
  greenCatItems = JSON.parse(greenCatItems + "] }");

  return (
    <div className="inspectionContainer">
      <div className="dropbtn" onClick={() => { setHideGood(!hideGood); }}>
        <div className="passingTitle">Items Passing Inspection</div>
        {hideGood ? <Collapse className="expandCollapse" title="Collapse" />
          : <Expand className="expandCollapse" title="Expand" />}
      </div>
      {hideGood ?
        <div className="dropdown-content">
          {greenCatItems.categories.map((c, catId) => {
            return (
              (c.items.length !== 0) ? <React.Fragment key={"green-" + catId}>
                <div key={"gC-" + catId} className="goodCategory"><b>{c.catDesc}</b></div>
                <div key={"iR-" + catId} className="itemRow">
                  <div key={"cW-" + catId} className="checkWrap">
                    <Verified key={"V-" + catId} className="check" title="Verified" />
                  </div>
                  <div key={"gI-" + catId} className="goodItem">
                    {c.items.map((i, itemId) => {
                      return (
                        (i.rate.type === "Stop3" || i.rate.type === "Stop2") && i.rate.rating === "Green"
                          ? <React.Fragment key={"lWrap-"+itemId}>{(itemId === 0) ? <>{i.itemDesc}</> : 
                          <>{", " + i.itemDesc}</>}</React.Fragment>
                          : null
                      );
                    })}                  
                  </div>
                </div></React.Fragment> : null
            );
          })}
        </div> : null}
    </div>
  )
}

export default ReportInspection