import React, { useContext, useState } from 'react'
import AppContext from '../../appContext'
import { ReactComponent as EditIcon } from "../../assets/edit.svg";
import './inspectHead.css'

function InspectHead({ updateReportRef, updateCustomerRef, ...props }) {

  const gVar = useContext(AppContext);
  const [editRef, setEditRef] = useState(false);
  const [editCust, setEditCust] = useState(false);
  const [reportRef, setReportRef] = useState("");
  const [customerRef, setCustomerRef] = useState("");

  // onClick for report ref save
  const updateRef = () => {
    if (reportRef.length > 2) {
      // Set new ref, pass in the old ref to remove old files
      updateReportRef(reportRef, gVar.iData.reportRef.value);
      // exit edit mode
      setEditRef(false); 
    }
  };

  // onClick for customer ref ref save
  const updateCust = () => {
    if (customerRef.length > 0) {
      // Set new customer
      updateCustomerRef(customerRef);
      // exit edit mode
      setEditCust(false);
    }
  };

  return (
    <div className="headerTile">
      <div className="headerLogo">
        <img className="logo" src={gVar.iData.logo} alt="logo goes here" ></img>
      </div>
      <div className="headerTitle">
        {gVar.iData.inspectTitle}
      </div>
      <div className="headerRef">
        <div className="refLabel">
          {gVar.iData.reportRef.label}
        </div>
        {editRef ? (
          <div className="refData">
            <div className="saveButton" onClick={updateRef}>Save</div>
            <input autoFocus id="rr" className="headerEntry" value={reportRef} onChange={(e) => setReportRef(e.target.value.toUpperCase())} />
          </div>
        ) : (
          <div className="refData" onClick={() => setEditRef(true)} >
            <EditIcon className="editIcon" title={"Edit " + gVar.iData.reportRef.label} />
            {gVar.iData.reportRef.value}
          </div>
        )}
        <div className="refLabel">
          {gVar.iData.customerRef.label}
        </div>
        {editCust ? (
          <div className="refData">
            <div className="saveButton" onClick={updateCust}>Save</div>
            <input autoFocus id="uc" className="headerEntry" value={customerRef} onChange={(e) => setCustomerRef(e.target.value)} />
          </div>
        ) : (
          <div className="refData" onClick={() => setEditCust(true)} >
            <EditIcon className="editIcon" title={"Edit " + gVar.iData.customerRef.label} />
            {gVar.iData.customerRef.value}
          </div>
        )}
      </div>
    </div>
  );
}

export default InspectHead;