import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';

export const fbapp = firebase.initializeApp({
  "projectId": process.env.REACT_APP_FBPID,
  "appId": process.env.REACT_APP_FBAID,
  "storageBucket": process.env.REACT_APP_FBSB,
  "locationId": process.env.REACT_APP_FBL,
  "apiKey": process.env.REACT_APP_FBAPI,
  "authDomain": process.env.REACT_APP_FBAD,
  "messagingSenderId": process.env.REACT_APP_FBMSID,
  "measurementId": process.env.REACT_APP_FBMIDB
});