import React, { useContext, useState } from 'react';
import AppContext from '../appContext';
import './Access.css';

function Access() {

  var acEntry = "";
  const [tryAgain, setTryAgain] = useState(false);

  const { setAccessCode, iAuthData } = useContext(AppContext);

  const checkAccessCode = () => {
    if (iAuthData.iac === acEntry) {
      setAccessCode(acEntry);
    } else {
      setTryAgain(true);
      document.getElementById("ace").value = "";
    }
  }
  
  return (
    <div className="homeWrapper">
      <div className="homeTitle">
        Enter Access Code
      </div>
      <div className="actionCol">
        <div className="homeEntryWrapper">
          <div className="homeEntryLabel">Access Code</div>
          <input id="ace" className="homeEntry" defaultValue={acEntry} onChange={(e) => acEntry = e.target.value }/>
            {tryAgain ? <div className="errLabel">Try Again</div> : null }
            <div className="entryButton" onClick={checkAccessCode}>Continue</div>
        </div>
      </div>
    </div>
  )
};

export default Access;