import React, { useContext } from 'react'
import AppContext from '../../appContext'
import './note.css'

function Note({ catId, itemId, attr, allowInput, rate, ...props }) {

  const { iData, setiData, setIsSaved } = useContext(AppContext);
  const iDataNew = { ...iData };

  var isEnabled = true;

  // disable if this item is a stop2 or stop3 and rating is green or off
  if (rate.type === "Stop2" || rate.type === "Stop3") {
    if ((allowInput === null || !allowInput) && isEnabled && (rate.rating === "Green" || rate.rating === "")) {
      isEnabled = false;
    }
  }

  const saveNote = (e) => {
    iDataNew.categories[catId].items[itemId][attr].value = e.target.value;
    setiData(iDataNew);
    // set flag for data no longer in sync with cloud
    setIsSaved(false);
    // re-render parent
    props.onItemChange(e.target.value);
  }

  return (
    <div className="note">
      <div className="noteLabel" >
        {props.label}
      </div>
      <div className="noteData">
        <textarea
          name={"Note-" + catId + "-" + itemId + "-" + attr}
          className="noteText"
          type="text"
          rows={props.rowCount}
          onChange={saveNote}
          disabled={isEnabled ? false : true}
          value={iData.categories[catId].items[itemId][attr].value}
        />
      </div>
    </div>
  );
}
export default Note;