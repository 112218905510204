import React, { useState } from 'react'
import PhotoCapture from '../photoCapture/photoCapture'
import Note from '../note/note'
import StopLight2 from '../stoplights/stoplight2'
import StopLight3 from '../stoplights/stoplight3'
import './inspectItem.css'

const InspectItem = ({ catId, itemId, item, imageData, takePhotoOn, clearItem, ...props }) => {

  const [itemChange, setItemChange] = useState("");
  const rate = item.rate;

  var tileStyle = "";

  // used for child to re-render parent
  const handlePhotoChange = () => {
    // use a toggle value to ensure change with each photo capture
    if (itemChange === "toggleOn") {
      setItemChange("toggleOff");
    } else {
      setItemChange("toggleOn");
    }
  }

  const handleNoteChange = (newValue) => {
    // note values can just use the newValue of the note
    setItemChange(newValue);
  }

  // called by stoplights to re-render an possibly clear the item
  // clear is done on any change to turn stoplight off, or change to Green only when takePhotoOn is Yellow or Red
  const handleLightChange = (newValue, takePhotoOn) => {
    setItemChange(newValue);
    if (newValue === "" || (newValue === "Green" && (takePhotoOn === "Yellow" || takePhotoOn === "Red"))) {
      clearItem(catId, itemId);
    }
  };

  const hasAllInputs = (item) => {
    // if a note is part of the item, and has no data, then return false
    if (item.hasPhoto) {
      if (!imageData) { return false };
    }
    if (item.note1) {
      if (item.note1.value.length === 0) { return false };
    }
    if (item.note2) {
      if (item.note2.value.length === 0) { return false };
    }
    if (item.note3) {
      if (item.note3.value.length === 0) { return false };
    }
    // otherwise return true
    return true;
  }

  // First set Tile to either Mandatory or Optional
  (item.isMandatory) ? tileStyle = "itemMandatory" : tileStyle = "itemOptional";
  // update tile based on stoplight type, rating, and takePhotoOn settings
  if (rate.type === "Stop2" || rate.type === "Stop3") {
    // If rating is Green, then you don't need fields, but you may require a photo still
    // Green + takePhotoOn of Yellow or Red = complete
    if (rate.rating === "Green") {
      if (takePhotoOn === "Yellow" || takePhotoOn === "Red") {
        tileStyle = "itemComplete";
      } else {
          // Green with takePhotoOn of "Green" + existing imageData = complete
          if ((takePhotoOn === "Green" || takePhotoOn === "Any") && imageData) {
          tileStyle = "itemComplete";
        }
      }
    } else {
      if (rate.rating === "Yellow" || rate.rating === "Red") {
        // check photo first if one is required
        if ((rate.rating === "Yellow" && (takePhotoOn === "Green" || takePhotoOn === "Yellow")) ||
          (rate.rating === "Red" && (takePhotoOn === "Green" || takePhotoOn === "Yellow" || takePhotoOn === "Red"))) {
          if (hasAllInputs(item)) {
            tileStyle = "itemComplete";
          }
        }
      }
    }
  } else {
    // check for completed Note items
    if (rate.type === "Note" && hasAllInputs(item)) {
      tileStyle = "itemComplete";
    }
  }

  return (
    <div className={tileStyle}>
      <div className="itemTitle">
        {item.itemDesc}
      </div>
      {item.rate.type === 'Stop2' && (
        <StopLight2
          catId={catId}
          itemId={itemId}
          attr="rate"
          rn1={item.rateNote1}
          rn2={item.rateNote2}
          takePhotoOn={takePhotoOn}
          onItemChange={handleLightChange}
        />
      )}
      {item.rate.type === 'Stop3' && (
        <StopLight3
          catId={catId}
          itemId={itemId}
          attr="rate"
          rn1={item.rateNote1}
          rn2={item.rateNote2}
          rn3={item.rateNote3}
          takePhotoOn={takePhotoOn}
          onItemChange={handleLightChange}
        />
      )}
      {item.hasPhoto && (
        <PhotoCapture
          catId={catId}
          itemId={itemId}
          rate={rate}
          cn={rate.type === "Note" ? "webcam1" : "webcam2"}
          imageData={imageData}
          takePhotoOn={takePhotoOn}
          onItemChange={handlePhotoChange}
        />
      )}
      <div className="itemNotes">
        {item.note1 != null && (
          <Note
            catId={catId}
            itemId={itemId}
            rate={rate}
            attr="note1"
            label={item.note1.label}
            rowCount={item.note1.rows}
            allowInput={item.note1.allowInput}
            onItemChange={handleNoteChange}
          />
        )}
        {item.note2 != null && (
          <Note
            catId={catId}
            itemId={itemId}
            rate={rate}
            attr="note2"
            label={item.note2.label}
            rowCount={item.note2.rows}
            allowInput={item.note2.allowInput}
            onItemChange={handleNoteChange}
          />
        )}
        {item.note3 != null && (
          <Note
            catId={catId}
            itemId={itemId}
            rate={rate}
            attr="note3"
            label={item.note3.label}
            rowCount={item.note3.rows}
            allowInput={item.note3.allowInput}
            onItemChange={handleNoteChange}
          />
        )}
      </div>
    </div>
  );
}

export default InspectItem;