import React from 'react';
import './LoaderPage.css';

function LoaderPage(props) {

  return (
    <div className={props.pageStyle}>
      <div className="spinner">
        <img className="loadImg" src="images/InspectSpin.gif" alt="Loading"></img>
        <div className="loadTitle">{props.pageMsg}</div>
      </div>
    </div>
  )
};

export default LoaderPage;