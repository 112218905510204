import React, { useContext, useState } from 'react';
import AppContext from '../appContext';
import './Access.css';

function Customer() {

  const { setCustId, errCode, setErrCode } = useContext(AppContext);

  const [custEntry, setCustEntry] = useState("");
  const [badCust, setBadCust] = useState((errCode === "001" ? true : false));

  const checkCustId = () => {
      if (custEntry.length > 4) {
        setBadCust(false);
        setErrCode("");
        setCustId(custEntry);
      } else {
        setBadCust(true);
        setCustEntry("");
      }
  }
  
  return (
    <div className="homeWrapper">
      <div className="homeTitle">
        Enter Customer ID
      </div>
      <div className="actionCol">
        <div className="homeEntryWrapper">
          <div className="homeEntryLabel">Customer ID</div>
          <input id="ce" className="homeEntry" value={custEntry} onChange={(e) => { setCustEntry(e.target.value); } }/>
            {badCust ? <div className="errLabel">Try Again</div> : null }
            <div className="entryButton" onClick={checkCustId}>Continue</div>
        </div>
      </div>
    </div>
  )
};

export default Customer;