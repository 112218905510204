import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AppContext from "./appContext";
import { fbapp } from "./base";
// Style
import "./app.css";
// Pages
import LoaderPage from "./pages/LoaderPage";
import Home from "./pages/Home";
import Inspect from "./pages/inspect";
import Report from "./pages/report";
import ErrorPage from "./pages/ErrorPage";
import Customer from './pages/Customer';
import Access from "./pages/Access";
// Variables
var CryptoJS = require("crypto-js");

// Main function
function App() {

  // get and decrypt access code and custID from local if it exists
  var iac = localStorage.getItem('iac') || "";
  var ic = localStorage.getItem('ic') || "";
  if (iac !== "") {
    iac = CryptoJS.AES.decrypt(iac, process.env.REACT_APP_IK);
    iac = iac.toString(CryptoJS.enc.Utf8);
  }
  if (ic !== "") {
    ic = CryptoJS.AES.decrypt(ic, process.env.REACT_APP_IK);
    ic = ic.toString(CryptoJS.enc.Utf8);
  }

  // state variales
  // app level auth
  const [auth, setAuth] = useState(!!fbapp.auth().currentUser);
  // access Code (default from local storage)
  const [accessCode, setAccessCode] = useState(iac);
  // custId (defult from local storage)
  const [custId, setCustId] = useState(ic);
  // overall authorization state
  const [isAuth, setIsAuth] = useState(true);
  // do we have an IP match
  const [ipMatch, setIpMatch] = useState(false);
  // do we have an accessCode match
  const [acMatch, setAcMatch] = useState(false);
  // user ip
  const [ip, setIp] = useState("");
  // inspection definition json object
  const [errCode, setErrCode] = useState("");
  // authorization definiton
  const [iAuthData, setiAuthData] = useState([]);
  // inspection definiton
  const [iDef, setiDef] = useState([]);
  // inspection definition for the photos
  const [iDefPhotos, setiDefPhotos] = useState([]);
  // inspection data
  const [iData, setiData] = useState([]);
  // array of json objects for the photos
  const [iPhotos, setiPhotos] = useState([]);
  // flag to limit one finding area live at a time
  const [isCameraLive, setIsCameraLive] = useState(false);
  // flag to indicate if data/photos are saved
  const [isSaved, setIsSaved] = useState(true);

  // helper function for updating iPhotos
  const updatePhoto = (photoData, catId, itemId) => {
    const iPhotosNew = { ...iPhotos };
    iPhotosNew.categories[catId].items[itemId].photoData = photoData;
    setiPhotos(iPhotosNew);
    setIsCameraLive(false);
  };

  // create global variables, states and setters 
  // for use in any components that need them
  const glbVar = {
    auth: auth, setAuth,
    custId: custId, setCustId,
    isAuth: isAuth, setIsAuth,
    iAuthData: iAuthData,
    iDef: iDef,
    iDefPhotos: iDefPhotos, setiDefPhotos,
    iData: iData, setiData,
    iPhotos: iPhotos, setiPhotos,
    isCameraLive: isCameraLive,
    isSaved: isSaved,
    acMatch: acMatch,
    accessCode: accessCode, setAccessCode,
    errCode: errCode, setErrCode,
    setIsCameraLive,
    setIsSaved,
    updatePhoto,
  };

  // on start up we want set the the customer inspection definition
  useEffect(() => {

    // function to get the customer definition
    const getCustDef = async () => {
      // authenticate 
      if (!auth) {
        var fbAuth = await fbapp.auth().signInWithEmailAndPassword(process.env.REACT_APP_U, process.env.REACT_APP_P);
        setAuth(fbAuth);
      }
      // set firstore connection
      const db = fbapp.firestore();
      // get customer definition document
      const doc = await db.collection("customers").doc(custId).get();
      if (doc.exists) {
        setiDef(JSON.parse(doc.data().iDef));
        setiDefPhotos(JSON.parse(doc.data().iDefPhotos));
        setiAuthData(JSON.parse(doc.data().auth));
        setIsAuth(true);
        // get IP via remote fetch
        fetch('https://api.ipify.org/?format=json')
          .then(response => response.json())
          .then(json => setIp(json.ip))
      } else {
        console.log("001: customer doc doesn't exist");
        setErrCode("001");
        setIsAuth(false);
      }
    };
    // only call function once we have firebase auth and a cust id 
    if (custId) {
      getCustDef();
    }
    // only gets called on custId or auth change
  }, [custId, auth]);

  // if we don't have a customer 
  // or we tried and failed to find a definition for the customer entered
  // then render the customer entry
  if (custId === "" || errCode === "001") {
    return (
      <AppContext.Provider value={glbVar}>
        <div className="App">
          <Customer />
        </div>
      </AppContext.Provider>
    );
  } else {
    // encrypt and save custID to LocalStorage
    ic = CryptoJS.AES.encrypt(custId, process.env.REACT_APP_IK);
    localStorage.setItem("ic", ic);
  }

  // protect app render until initialized
  if (iAuthData === 0 || iDef.length === 0 || iDefPhotos.length === 0) {
    if (errCode) {
      // console.log("app.js: Not initialized but have error code of: " + errCode + ", so we'll return the error page.");
      return <ErrorPage errCode={errCode} />;
    } else {
      return <LoaderPage pageStyle="loadingWrapper" pageMsg="Please Wait"/>;
    }
  }

  // ToDo: uncomment the IP check section once live
  // IP check if we have an IP and Auth data
  if (isAuth && iAuthData.length !== 0 && ip !== "" && !ipMatch) {
    // ToDo: remove this line once IP check is back on
    setIpMatch(true);
    // var matchIP = false;
    // iAuthData.IP.map((i) => {
    //   if (i === ip) {
    //     setIpMatch(true);
    //     matchIP = true;
    //   }
    //   return 0;
    // })
    // if (!matchIP) {
    //   console.log("003: no match on IP: " + ip);
    //   setErrCode("003");
    //   setIsAuth(false);
    // }
  }

  // Access Code check (if we have everything else)
  if (isAuth && iAuthData.length !== 0 && ipMatch && !acMatch) {
    if (iAuthData.iac === accessCode) {
      setAcMatch(true);
      // encrypt and save accessCode to LocalStorage
      iac = CryptoJS.AES.encrypt(accessCode, process.env.REACT_APP_IK);
      localStorage.setItem("iac", iac);
    } else {
      return (
        <AppContext.Provider value={glbVar}>
          <div className="App">
            <Access />
          </div>
        </AppContext.Provider>
      );
    }
  }

  // ToDo: Not sure if we need this anymore
  if (!acMatch) {
    if (errCode) {
      return <ErrorPage errCode={errCode} />;
    } else {
      return <LoaderPage pageStyle="loadingWrapper" pageMsg="Please Wait"/>;;
    }
  }

  if (isAuth) {
    return (
      <Router>
        <AppContext.Provider value={glbVar}>
          <div className="App">
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/inspect" component={Inspect} />
              <Route path="/report" component={Report} />
            </Switch>
          </div>
        </AppContext.Provider>
      </Router>
    );
  } else {
    if (errCode) {
      return <ErrorPage errCode={errCode} />;
    } else {
      return <LoaderPage pageStyle="loadingWrapper" pageMsg="Please Wait"/>;
    }
  }
}

export default App;