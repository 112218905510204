import React, { useState, useEffect } from "react";
import { fbapp } from "../base";
import AppContext from "../appContext";
// Components
import ReportInspection from "../reportComponents/reportInspection/reportInspection";
import ReportInfoPhotos from "../reportComponents/reportInfoPhotos/reportInfoPhotos";
import ReportHead from "../reportComponents/reportHead/reportHead"
import ReportFindings from "../reportComponents/reportFindings/reportFindings";
import ErrorPage from "../pages/ErrorPage";
import LoaderPage from "../pages/LoaderPage";
// Style
import './pubReport.css';

var CryptoJS = require("crypto-js");

const PubReport = () => {

  // connect to querystring parms
  const params = new URLSearchParams(window.location.search)
  // get encrypted customer and report # params
  var r = params.get("r") || "";
  var c = params.get("c") || "";
  // decrypt query string parms
  var bytes = "";
  if (r !== "") {
    bytes = CryptoJS.AES.decrypt(r, process.env.REACT_APP_PK);
    r = bytes.toString(CryptoJS.enc.Utf8);
    r = r.toUpperCase();
  }
  if (c !== "") {
    bytes = CryptoJS.AES.decrypt(c, process.env.REACT_APP_PK);
    c = bytes.toString(CryptoJS.enc.Utf8);
  }

  // state variables
  // create and set app level auth (for use when accessing storage)
  const [auth, setAuth] = useState(!!fbapp.auth().currentUser);
  const [reportRef] = useState(r);
  const [customerRef] = useState(c);
  const [iData, setiData] = useState([]);
  const [iPhotos, setiPhotos] = useState([]);
  // const [iPhotosFound, setiPhotosFound] = useState(false);
  // const [iDataFound, setiDataFound] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fileErr, setFileErr] = useState(false);

  // create global variables, states and setters for use in any components that need them
  const glbVar = {
    customerRef: customerRef,
    reportRef: reportRef,
    iData: iData,
    iPhotos: iPhotos
  };

  // similar to home.js, the useEffect gets the saved inspection files and put into variables
  // the difference is that we don't have to handle the custId accessCode IP authentication logic
  useEffect(() => {

    const getInspection = async () => {
      setIsLoading(true);
      // authenticate 
      if (!auth) {
        var fbAuth = await fbapp.auth().signInWithEmailAndPassword(process.env.REACT_APP_PU, process.env.REACT_APP_PP);
        setAuth(fbAuth);
      }
      // connect to firebase storage
      const storageRef = fbapp.storage().ref();
      try {
        // set file ref to the inspect on data file
        var iDataFileRef = storageRef.child(customerRef + "/" + reportRef + "-i.json");
        // get URL to use for fetching
        var iDataURL = await iDataFileRef.getDownloadURL();
        var iDataResponse = await fetch(iDataURL);
        var iDataJSON = await iDataResponse.json();

        // set file ref to the inspect on data file
        var iPhotoFileRef = storageRef.child(customerRef + "/" + reportRef + "-p.json");
        // get URL to use for fetching
        var iPhotosURL = await iPhotoFileRef.getDownloadURL();
        var iPhotosResponse = await fetch(iPhotosURL);
        var iPhotosJSON = await iPhotosResponse.json();

        setiData(iDataJSON);
        setiPhotos(iPhotosJSON);
        setIsLoading(false);

      } catch (err) {
        console.log(err);
        console.log("Json files for customer " + customerRef + " not found.")
        setFileErr(true);
        setIsLoading(false);
      };
    }
    // get inspection def once authorized
    getInspection();

  }, [reportRef, customerRef, auth]);

  // put up the error page if the querystring doesn't have both a C and an R param)
  if (!customerRef || !reportRef) {
    return <ErrorPage errCode="P001" />;
  }
  // put up the error page if we have an error getting the files
  if (fileErr) {
    return <ErrorPage errCode="P002" />;
  }
  // protect until data exists
  if (isLoading || iData.length === 0 || iPhotos.length === 0) {
    return <LoaderPage pageStyle="loadingWrapper" pageMsg="Please Wait"/>;
  }
  const hRefmailto = "mailto:"+iData.mailto+"?subject=re: "+iData.reportRef.label+" "+reportRef;
  const hReftel = "tel:"+iData.tel;

  // otherwise we're good to go!
  return (
    <AppContext.Provider value={glbVar}>
      <ReportHead />
      <div className="contactLinks">
        <a href={hReftel}>Call us</a>
        <a href={hRefmailto} target="_blank" rel="noopener noreferrer">eMail us</a>
      </div>
      <ReportInspection />
      <ReportInfoPhotos />
      <ReportFindings />
    </AppContext.Provider>
  )
}

export default PubReport;