import React from 'react';
import './ErrorPage.css';

function ErrorPage(props) {

  return (
    <div className="errorWrapper">
      <h2>
        Oops, something went wrong ({props.errCode})
      </h2>
    </div>
  )
};

export default ErrorPage;