import React, { useContext, useState } from 'react';
import { useHistory } from "react-router-dom";
import AppContext from '../appContext';
import { fbapp } from "../base";

// Pages
import LoaderPage from "../pages/LoaderPage";
// Styles
import './Home.css';

function Home() {

  // local state variables
  const [reportRef, setReportRef] = useState("");
  const [customerRef, setCustomerRef] = useState("");
  const [inputRef, setInputRef] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [fileErr, setFileErr] = useState(false);

  // context
  const history = useHistory();
  const { ...gVar } = useContext(AppContext);

  const newInspection = () => {
    // create a working variable for iDef so we can start with a fresh def and add a few intial fields
    var newiDef = JSON.parse(JSON.stringify(gVar.iDef));
    var newiDefPhotos = JSON.parse(JSON.stringify(gVar.iDefPhotos));

    var today = new Date();
    // get customer name, report reference, and date
    newiDef.customerRef.value = customerRef;
    newiDef.reportRef.value = reportRef;
    newiDef.reportRef.date = today.toLocaleString("default", { month: "short" })
      + " " + today.getUTCDate()
      + ", " + today.getUTCFullYear();
    // clear iPhotos initially 
    gVar.setiPhotos(newiDefPhotos);
    // set initial iData with newDef
    gVar.setiData(newiDef);
    gVar.setIsSaved(true);
    history.push("/inspect");
  }

  const getInspection = async () => {
    setIsLoading(true);
    // connect to firebase storage
    const storageRef = fbapp.storage().ref();
    try {
      // set file ref to the inspect on data file
      var iDataFileRef = storageRef.child(gVar.custId + "/" + reportRef + "-i.json");
      // get URL to use for fetching
      var iDataURL = await iDataFileRef.getDownloadURL();
      var iDataResponse = await fetch(iDataURL);
      var iDataJSON = await iDataResponse.json();
      
      // set file ref to the inspect on data file
      var iPhotoFileRef = storageRef.child(gVar.custId + "/" + reportRef + "-p.json");
      // get URL to use for fetching
      var iPhotosURL = await iPhotoFileRef.getDownloadURL();
      var iPhotosResponse = await fetch(iPhotosURL);
      var iPhotosJSON = await iPhotosResponse.json();

      // save inspection data to state
      gVar.setiData(iDataJSON);
      gVar.setiPhotos(iPhotosJSON);
      history.push("/report");

    } catch (err) {
      console.log(err);
      console.log("Json files for customer " + customerRef + " not found.")
      setFileErr(true);
      setIsLoading(false);
    };
  }

  // put up loading page until we have the iDef
  if (isLoading || gVar.iDef.length === 0) {
    return <LoaderPage pageStyle="loadingWrapper" pageMsg="Please Wait"/>;
  }

  return (
    <div className="homeWrapper">
      <div className="homeLogo">
        <img className="homeLogoImg" src={gVar.iDef.logo} alt="logo goes here" ></img>
      </div>
      <div className="homeTitle">
        {gVar.iDef.inspectTitle}
      </div>
      <div className="actionCol">
        {
          (inputRef === "New")
            ?
            <div className="homeEntryWrapper">
              <div className="homeEntryLabel">{gVar.iDef.reportRef.label}</div>
              <input autofocus id="rr" className="homeEntry" value={reportRef}
                onChange={(e) => setReportRef(e.target.value.toUpperCase())} />
              <div className="homeEntryLabel">{gVar.iDef.customerRef.label}</div>
              <input id="cust" className="homeEntry"
                onChange={(e) => setCustomerRef(e.target.value)} />
              {(reportRef.length > 2 && customerRef.length > 0)
                ? <div className="entryButton" onClick={newInspection}>Start Inspection</div>
                : <div className="inactiveButton">Enter Valid Data</div>
              }
            </div>
            :
            <div className="actionButton" onClick={() => { setInputRef("New") }}>New Inspection</div>
        }
        {
          (inputRef === "Get")
            ?
            <div className="homeEntryWrapper">
              <div className="homeEntryLabel">{gVar.iDef.reportRef.label}</div>
              <input autoFocus id="rr" className="homeEntry" value={reportRef}
                onChange={(e) => setReportRef(e.target.value.toUpperCase())} />
              {fileErr && <div className="errLabel">Not Found. Try Again</div>}
              {(reportRef.length > 2)
                ? <div className="entryButton" onClick={getInspection}>Get Inspection</div>
                : <div className="inactiveButton">Enter Valid Data</div>
              }
            </div>
            :
            <div className="actionButton" onClick={() => { setInputRef("Get") }}>Get Inspection</div>
        }
      </div>
    </div>
  )
};

export default Home;