import React, { useContext } from 'react'
import AppContext from '../appContext'
import ReportInspection from "../reportComponents/reportInspection/reportInspection";
import ReportInfoPhotos from "../reportComponents/reportInfoPhotos/reportInfoPhotos";
import ReportHead from "../reportComponents/reportHead/reportHead"
import ReportFindings from "../reportComponents/reportFindings/reportFindings";
import LoaderPage from "../pages/LoaderPage";
import { Link } from "react-router-dom";
import "./report.css"
// Variables
var CryptoJS = require("crypto-js");

function Report() {

  const { iPhotos, setiData, updatePhoto, iData, ...gVar } = useContext(AppContext);

  // protect app render until initialized
  if (gVar.iAuthData === 0 || iData.length === 0 || iPhotos.length === 0) {
    return <LoaderPage pageStyle="loadingWrapper" pageMsg="Please Wait"/>;
  }

  // ToDo: count # of incomplete mandatory items
  
  //
  // save public facing report to clipboard every time we brin up a report
  //
  // encrypt parms
  var c = CryptoJS.AES.encrypt(iData.custId, process.env.REACT_APP_PK).toString();
  var r = CryptoJS.AES.encrypt(iData.reportRef.value, process.env.REACT_APP_PK).toString();
  // copy public facing report to clipboard (with function to encode special querystring characters)
  var publicURL = window.location.origin + "?c=" + encodeURIComponent(c) + "&r=" + encodeURIComponent(r);
  // add to clipboard (very odd code, but apparently the way it's done)
  const el = document.createElement('textarea');
  el.value = publicURL;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  // console.log("public URL copied to clipboard: Customer: " + iData.custId + ", Report Ref: "+iData.reportRef.value);

  return (
    <div className="reportAll">
      <ReportHead />
      <ReportInspection />
      <ReportInfoPhotos />
      <ReportFindings />
      <div className="actionRow">
        <Link to="/inspect">
          <div className="actionButton" >Back to Inspection</div>
        </Link>
        <Link to="/">
          <div className="actionButton">Home</div>
        </Link>
      </div>
    </div>
  )
}

export default Report

