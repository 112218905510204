import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import PubReport from './pages/pubReport';
import Reset from './pages/Reset';

// get querystring
const params = new URLSearchParams(window.location.search);

if (params.get("resetDevice")) {
  ReactDOM.render(
    <React.StrictMode>
      <Reset />
    </React.StrictMode>,
    document.getElementById('root'));
} else {
  if (params.get("c") && params.get("r")) {
    // Public Inspection Report
    ReactDOM.render(
      <React.StrictMode>
        <PubReport />
      </React.StrictMode>,
      document.getElementById('root'));
  } else {
    // Private Inspector App
    ReactDOM.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>,
      document.getElementById('root'));
  }
}