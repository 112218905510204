import React, { useState, useContext } from 'react';
import AppContext from '../../appContext';
import LoaderPage from "../../pages/LoaderPage";
import { ReactComponent as Expand } from "../../assets/Expand.svg";
import { ReactComponent as Collapse } from "../../assets/Collapse.svg";
// styles come from reportInspection and reportFindings

function ReportInfoPhotos() {

  const gVar = useContext(AppContext);
  const [mainImg, setMainImg] = useState("");
  const [mainRating, setMainRating] = useState("");
  const [mainItem, setMainItem] = useState("");
  const [mainCat, setMainCat] = useState("");
  const [rateNote, setRateNote] = useState("");
  const [hideGood, setHideGood] = useState(false);

  // set main tile to show 
  const setImg = (catId, itemId) => {
    //console.log("catId: "+catId+" itemId: "+itemId);
    setMainImg(gVar.iPhotos.categories[catId].items[itemId].photoData)
    setMainRating(gVar.iData.categories[catId].items[itemId].rate.rating)
    setMainItem(gVar.iData.categories[catId].items[itemId]);
    setMainCat(gVar.iData.categories[catId]);
    setRateNote("");
    // if there's a green rateNote, set it
    if (gVar.iData.categories[catId].items[itemId].rateNote1) {
      setRateNote(gVar.iData.categories[catId].items[itemId].rateNote1);
    }
  }

  // initial load protection
  if (!gVar.iPhotos || !gVar.iPhotos.categories) {
    return <LoaderPage pageStyle="loadingWrapper" pageMsg="Please Wait"/>;
  }

  const clrImg = () => {
    setMainImg("");
  }

  return (
    <div className="inspectionContainer">
      <div className="dropbtn" onClick={() => { setHideGood(!hideGood); }}>
        <div className="passingTitle">{gVar.iData.infoPhotoTitle ? gVar.iData.infoPhotoTitle : "Informational Photos"}</div>
        {hideGood ? <Collapse className="expandCollapse" title="Collapse" />
          : <Expand className="expandCollapse" title="Expand" />}
      </div>
      {hideGood ?
        <div className="findings">
          <div className="thumbs">
            {
              gVar.iPhotos.categories.map((c, catId) => {
                return (
                  c.items.map((i, itemId) => {
                    const rating = gVar.iData.categories[catId].items[itemId].rate.rating;
                    return (
                      (i.photoData && rating === "Green")
                        ? <div key={"fd" + catId + "-" + itemId} className={rating ? "thumb" + rating : "thumb"}>
                          <div key={"iW-" + catId + "-" + itemId} className="imgWrap">
                            <img className="imgThumb" src={i.photoData} alt="finding" onClick={() => setImg(catId, itemId)} />
                          </div>
                          <div key={"dW-" + catId + "-" + itemId} className="descriptionWrap">
                            {gVar.iData.categories[catId].items[itemId].itemDesc}
                          </div>
                        </div>
                        : null
                    )
                  }
                  )
                )
              })
            }
          </div>
          <div className="photoDetail">
            {(mainImg === "")
              ? <></>
              : <div className="fsWrap" onClick={clrImg}>
                <div className={mainRating ? "mainWrap" + mainRating : "mainWrap"}>
                <div className="findingPhotoTitle">{mainCat.catDesc} - {mainItem.itemDesc}</div>
                  {rateNote ? <div className="mainDetailsWrap">
                    <b>Rating Note:</b> {rateNote}
                  </div> : <></>}
                  {mainItem.note1 ? mainItem.note1.value.trim() ? <div className="mainDetailsWrap">
                    <b>{mainItem.note1.label}:</b> {mainItem.note1.value}
                  </div> : <></> : <></>}
                  {mainItem.note2 ? mainItem.note2.value.trim() ? <div className="mainDetailsWrap">
                    <b>{mainItem.note2.label}:</b> {mainItem.note2.value}
                  </div> : <></> : <></>}
                  {mainItem.note3 ? mainItem.note3.value.trim() ? <div className="mainDetailsWrap">
                    <b>{mainItem.note3.label}:</b> {mainItem.note3.value}
                  </div> : <></> : <></>}
                  <div className="imgMainWrap">
                    <img src={mainImg} className="imgMain" alt="main" />
                  </div>
                </div>
              </div>}
          </div>
        </div> : null}
    </div>
  )
}

export default ReportInfoPhotos;