import React, { useContext } from "react";
import AppContext from '../../appContext';
import './reportHead.css'


function ReportHead() {

    const { iData } = useContext(AppContext);

    return (
        <div className="headerContainer">
            <div className="reportLogo">
                <img className="Logo" src={iData.logo} alt="logo goes here" ></img>
            </div>
            <div className="reportTitle">
                {iData.reportTitle}
            </div>
            <div className="reportRefContainer">
                <div className="reportRef">
                    {iData.customerRef.label}: <b>{iData.customerRef.value}</b>
                </div>
                <div className="reportRef">
                    Date: <b>{iData.reportRef.date}</b>
                </div>
                <div className="reportRef">
                {iData.reportRef.label}: <b>{iData.reportRef.value}</b>
                </div>
            </div>
        </div>
    )
}

export default ReportHead;