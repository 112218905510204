import React, { useState, useContext } from 'react';
import AppContext from '../../appContext';
import './reportFindings.css';

const ReportFindings = () => {

  const gVar = useContext(AppContext);
  const [mainImg, setMainImg] = useState("");
  const [mainRating, setMainRating] = useState("");
  const [mainItem, setMainItem] = useState("");
  const [mainCat, setMainCat] = useState("");
  const [rateNote, setRateNote] = useState("");

  // set main tile to show 
  const setImg = (catId, itemId) => {
    //console.log("catId: "+catId+" itemId: "+itemId);
    setMainImg(gVar.iPhotos.categories[catId].items[itemId].photoData)
    setMainRating(gVar.iData.categories[catId].items[itemId].rate.rating)
    setMainItem(gVar.iData.categories[catId].items[itemId]);
    setMainCat(gVar.iData.categories[catId]);
    setRateNote("");
    // if Stop2, and rating = "Red", get RateNote2
    if (gVar.iData.categories[catId].items[itemId].rate.type === "Stop2") {
      if (gVar.iData.categories[catId].items[itemId].rate.rating === "Red") {
        setRateNote(gVar.iData.categories[catId].items[itemId].rateNote2);
      }
    } else {
      // if Stop3, rating "Yellow", get RateNote2, rating "Red", get RateNote3
      if (gVar.iData.categories[catId].items[itemId].rate.type === "Stop3") {
        if (gVar.iData.categories[catId].items[itemId].rate.rating === "Yellow") {
          setRateNote(gVar.iData.categories[catId].items[itemId].rateNote2);
        } else {
          if (gVar.iData.categories[catId].items[itemId].rate.rating === "Red") {
            setRateNote(gVar.iData.categories[catId].items[itemId].rateNote3);
          }
        }
      }
    }
  }

  const clrImg = () => {
    setMainImg("");
  }

  // initial load protection
  if (gVar.iPhotos && gVar.iPhotos.categories) {
    return (
      <div className="findings">
        <div className="findingsHeadingBar">
          <div className="findingsTitle">{gVar.iData.findingPhotoTitle ? gVar.iData.findingPhotoTitle : "Noteworthy Findings"}</div>
          <div className="legend">Legend:
            <div className="redBox">Requires Attention</div>
            <div className="yellowBox">Watch</div>
            <div className="unratedBox">General (unrated)</div>
          </div>
        </div>
        <div className="thumbs">
          {
            gVar.iPhotos.categories.map((c, catId) => {
              return (
                c.items.map((i, itemId) => {
                  const rating = gVar.iData.categories[catId].items[itemId].rate.rating;
                  return (
                    (i.photoData && rating !== "Green")
                      ? <div key={"fd" + catId + "-" + itemId} className={rating ? "thumb" + rating : "thumb"}>
                        <div key={"iW-" + catId + "-" + itemId} className="imgWrap">
                          <img className="imgThumb" src={i.photoData} alt="finding" onClick={() => setImg(catId, itemId)} />
                        </div>
                        <div key={"dW-" + catId + "-" + itemId} className="descriptionWrap">
                          {gVar.iData.categories[catId].items[itemId].itemDesc}
                        </div>
                      </div>
                      : null
                  )
                }
                )
              )
            })
          }
        </div>
        <div className="photoDetail">
          {(mainImg === "")
            ? <></>
            : <div className="fsWrap" onClick={clrImg}>          
              <div className={mainRating ? "mainWrap" + mainRating : "mainWrap"}>
              <div className="findingPhotoTitle">{mainCat.catDesc} - {mainItem.itemDesc}</div>  
                {rateNote ? <div className="mainDetailsWrap">
                  <b>Rating Note:</b> {rateNote}
                </div> : <></>}
                {mainItem.note1 ? mainItem.note1.value.trim() ? <div className="mainDetailsWrap">
                  <b>{mainItem.note1.label}:</b> {mainItem.note1.value}
                </div> : <></> : <></>}
                {mainItem.note2 ? mainItem.note2.value.trim() ? <div className="mainDetailsWrap">
                  <b>{mainItem.note2.label}:</b> {mainItem.note2.value}
                </div> : <></> : <></>}
                {mainItem.note3 ? mainItem.note3.value.trim() ? <div className="mainDetailsWrap">
                  <b>{mainItem.note3.label}:</b> {mainItem.note3.value}
                </div> : <></> : <></>}
                <div className="imgMainWrap">
                  <img src={mainImg} className="imgMain" alt="main" />
                </div>
              </div>
            </div>}
        </div>
      </div>
    )
  };
}

export default ReportFindings;

