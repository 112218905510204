import React, { useState, useContext } from "react";
import AppContext from "../../appContext";
import Webcam from "react-webcam";
import { ReactComponent as AddPhoto } from "../../assets/addPhoto.svg";
import "./photoCapture.css";

function PhotoCapture({ catId, itemId, imageData, rate, takePhotoOn, ...props }) {
  // global context
  const { isCameraLive, setIsCameraLive, updatePhoto, setIsSaved } = useContext(AppContext);
  // local state
  const webcamRef = React.useRef(null);
  const [webcamActive, setWebcamActive] = useState(false);
  // local variables
  var isEnabled = true;

  // logic for disabling the photo placeholder (different than notes because Green can still require a photo)
  if (isEnabled && (rate.type === "Stop2" || rate.type === "Stop3")) {
    if (rate.rating === "" ||
      (rate.rating === "Green" && (takePhotoOn === "Yellow" || takePhotoOn === "Red"))) {
      isEnabled = false;
    }
  }

  // onClick for take or retake photo
  const onEmptyCaptureClick = () => {
    setWebcamActive(true);
    // prevent other instances from capture
    setIsCameraLive(true);
  };

  // onClick for active webcam
  // we dont actually need to use the callback, that is an optimization
  // that makes things more complicated
  const captureNewImage = () => {
    const webcamData = webcamRef.current.getScreenshot();
    // call our simplified photo updater from context
    updatePhoto(webcamData, catId, itemId);
    setWebcamActive(false);
    setIsSaved(false);
    // re-render parent
    props.onItemChange();
  };

  // Get webcam facing mode from .env if it exists, if not, use user facing by default
  const webcamFacing = process.env.REACT_APP_WEBCAM_MODE ? process.env.REACT_APP_WEBCAM_MODE : "environment";

  const videoConstraints = {
    facingMode: { exact: webcamFacing },
    width: {
      min: 200,
      ideal: window.innerWidth * 0.75,
      max: window.innerWidth * 0.75,
    },
    height: {
      min: 200,
      ideal: window.innerWidth * 0.75,
      max: window.innerWidth * 0.75,
    },
    aspectRatio: 1,
  };

  return (
    <div className="photoTile">
      {webcamActive ? (
        <div>
          <div className="photoPlaceholder" />
          <div className={props.cn} onClick={captureNewImage}>
            <Webcam
              videoConstraints={videoConstraints}
              screenshotQuality={1}
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
            />
          </div>
        </div>
      ) : (
          <div
            onClick={(isCameraLive || !isEnabled) ? null : onEmptyCaptureClick}
            className="photoTile"
          >
            {!imageData ? (
              <>
                {isEnabled ? (<>
                  <AddPhoto className="photoPlaceholder" title="Take a photo" />
                  <div className="placeholderText">Take a photo</div>
                </>
                ) : <AddPhoto className="photoPlaceholderX" title="Take a photo" />
                }
              </>
            ) : (
                <>
                  <img className="photoHolder" src={imageData} alt="Finding"></img>
                  <div className="photoText">Retake Photo</div>
                </>
              )}
          </div>
        )}
    </div>
  );
}

export default PhotoCapture;