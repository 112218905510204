import React from "react";
import { fbapp } from "../base";
import './ErrorPage.css';

const Reset = () => {

    // Clear localstorage
    localStorage.removeItem("ic");
    localStorage.removeItem("iac");
    // sign out of firebase
    fbapp.auth().signOut().then(function() {
      console.log('Signed Out');
    }, function(error) {
      console.error('Sign Out Error', error);
    });

  return (
    <div className="errorWrapper">
      <h2>
        Device Reset Complete
      </h2>
    </div>
  )
}

export default Reset;