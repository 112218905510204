import React, { useContext, useState } from 'react'
import AppContext from '../../appContext'
import GreenOff from '../../assets/GreenOffXR.png'
import GreenOn from '../../assets/GreenOnXR.png'
import YellowOff from '../../assets/YellowOffXR.png'
import YellowOn from '../../assets/YellowOnXR.png'
import RedOff from '../../assets/RedOffXR.png'
import RedOn from '../../assets/RedOnXR.png'
import './stopLight.css'

const StopLight3 = (props) => {

    const gVar = useContext(AppContext);
    var iDataNew = gVar.iData;
    var rating = gVar.iData.categories[props.catId].items[props.itemId][props.attr].rating;
    const [lightState, setLightState] = useState({ Green: false, Yellow: false, Red: false });

    const greenClick = () => {
        if (rating==="Green") {
            setLightState({ Green: false, Yellow: false, Red: false });
            iDataNew.categories[props.catId].items[props.itemId][props.attr].rating = "";
            gVar.setiData(iDataNew);
            // set flag for data no longer in sync with cloud
            gVar.setIsSaved(false);
            props.onItemChange("",props.takePhotoOn);
        } else {
            setLightState({ Green: true, Yellow: false, Red: false })
            iDataNew.categories[props.catId].items[props.itemId][props.attr].rating = "Green";
            gVar.setiData(iDataNew);        }
            // set flag for data no longer in sync with cloud
            gVar.setIsSaved(false);
            props.onItemChange("Green",props.takePhotoOn);
    }

    const yellowClick = () => {
        if (rating === "Yellow") {
            setLightState({ Green: false, Yellow: false, Red: false })
            iDataNew.categories[props.catId].items[props.itemId][props.attr].rating = "";
            gVar.setiData(iDataNew);
            // set flag for data no longer in sync with cloud
            gVar.setIsSaved(false);
            props.onItemChange("",props.takePhotoOn);
        } else {
            setLightState({ Green: false, Yellow: true, Red: false })
            iDataNew.categories[props.catId].items[props.itemId][props.attr].rating = "Yellow";
            gVar.setiData(iDataNew);
            // set flag for data no longer in sync with cloud
            gVar.setIsSaved(false);
            props.onItemChange("Yellow",props.takePhotoOn);

        }
    }

    const redClick = () => {
        if (rating === "Red") {
            setLightState({ Green: false, Yellow: false, Red: false })
            iDataNew.categories[props.catId].items[props.itemId][props.attr].rating = "";
            gVar.setiData(iDataNew);
            // set flag for data no longer in sync with cloud
            gVar.setIsSaved(false);
            props.onItemChange("",props.takePhotoOn);
        } else {
            setLightState({ Green: false, Yellow: false, Red: true })
            iDataNew.categories[props.catId].items[props.itemId][props.attr].rating = "Red";
            gVar.setiData(iDataNew);
            // set flag for data no longer in sync with cloud
            gVar.setIsSaved(false);
            props.onItemChange("Red",props.takePhotoOn);
        }
    }

    // align rating with light state if set
    if(lightState.Green) { rating = "Green" };
    if(lightState.Yellow) { rating = "Yellow" };
    if(lightState.Red) { rating = "Red" };
    // console.log("Stored Rating: "+rating+", Light State: "+JSON.stringify(lightState));

    return (
        <div className="stackedLight">
            <div className="sideCenter">
                <img className="stopLight"
                    onClick={redClick}
                    src={(rating === "Red") ? {RedOn}.RedOn : {RedOff}.RedOff}
                    alt="bad">
                </img>
                <div className={(rating === "Red") ? "rateNoteRed" : "rateNote"}>{props.rn3}</div>
            </div>
            <div className="sideCenter">
                <img className="stopLight"
                    onClick={yellowClick}
                    src={(rating === "Yellow") ? {YellowOn}.YellowOn : {YellowOff}.YellowOff}
                    alt="caution">
                </img>
                <div className={(rating === "Yellow") ? "rateNoteYellow" : "rateNote"}>{props.rn2}</div>
            </div>
            <div className="sideCenter">
                <img className="stopLight"
                    onClick={greenClick}
                    src={(rating === "Green") ? {GreenOn}.GreenOn : {GreenOff}.GreenOff}
                    alt="good">
                </img>
                <div className={(rating === "Green") ? "rateNoteGreen" : "rateNote"}>{props.rn1}</div>            
            </div>
        </div>
    );
}

export default StopLight3;